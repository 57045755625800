const toTop = require("./scripts/toTop");
const heroBG = require("./scripts/heroBG");
const heroBtn = require("./scripts/heroBtn");

window.addEventListener("load", e => {
  e.preventDefault();
  document.querySelector(".footer-btn").addEventListener("click", () => {
    toTop(document.querySelector("#header"));
  });
  heroBG();
});

window.addEventListener("load", e => {
  if (document.querySelector(".hero-btn")) {
    e.preventDefault();
    document.querySelector(".hero-btn").addEventListener("click", () => {
      heroBtn(document.querySelector("#main-description"));
    });
  }
});

//burger menu
window.addEventListener("load", () => {
  var menuBtn = document.querySelector(".burger-menu--btn");
  var menu = document.querySelector(".navigation");
  menuBtn.addEventListener("click", () => {
    if (menuBtn.classList.contains("burger-menu--btn-active")) {
      menuBtn.classList.remove("burger-menu--btn-active");
      menu.classList.remove("menu-show");
      // menu.setAttribute("style", "display: none");
    } else {
      menuBtn.classList.add("burger-menu--btn-active");
      menu.classList.add("menu-show");
      // menu.setAttribute("style", "display: unset");
    }
  });
});
