module.exports = function heroBG() {
  if (!document.querySelectorAll(".bg").length) {
    return;
  }
  //   const bgItem = document.querySelector(".bg-orig");
  const img = new Image();
  img.src = "/assets/images/maxres.jpg";
  img.onload = () => {
    document
      .querySelector("#bg-orig")
      .setAttribute("style", `background-image: url(${img.src});`);
    document.querySelector("#bg-orig").classList.add("loaded");
  };
};
